import { useEffect, useRef, useState } from "react";
import { getPublicJudgeByUserId } from "../../publicFunctions/publicBattleHandler";
import { pageScrollSettings } from "../../publicFunctions/publicHelpers/helpers";

const LINK_LIMIT = 35;
const LINK_LIMIT_MIN_TWO = LINK_LIMIT - 2;

function JudgeList({ forIndustryJudges, battleJudgesDoc, battleId }) {
  const [renderedJudgeList, setRenderedJudgeList] = useState([]);
  const [selectedJudgeUserId, setSelectedJudgeUserId] = useState(
    forIndustryJudges &&
      battleJudgesDoc?.industryJudges?.length &&
      battleJudgesDoc.industryJudges[0]?.public
      ? battleJudgesDoc.industryJudges[0].userId
      : !forIndustryJudges &&
        battleJudgesDoc?.proJudges?.length &&
        battleJudgesDoc.proJudges[0]?.public
      ? battleJudgesDoc.proJudges[0].userId
      : ""
  );
  const [renderedSelectedJudge, setRenderedSelectedJudge] = useState(null);
  const selectedJudgeRef = useRef(null);

  useEffect(() => {
    if (battleJudgesDoc) {
      const tempSelected =
        battleJudgesDoc?.industryJudges?.length &&
        battleJudgesDoc.industryJudges[0]?.public
          ? battleJudgesDoc.industryJudges[0].userId
          : !forIndustryJudges &&
            battleJudgesDoc?.proJudges?.length &&
            battleJudgesDoc.proJudges[0]?.public
          ? battleJudgesDoc.proJudges[0].userId
          : "";
      setSelectedJudgeUserId(tempSelected);
    }
  }, [battleJudgesDoc]);

  const renderSelectedJudge = async (judgeUserId) => {
    try {
      const now = new Date();
      const judgeData = await getPublicJudgeByUserId(judgeUserId);
      if (judgeData) {
        setRenderedSelectedJudge(
          <div className="selected-rules-judge-container">
            <img
              className={"judge-rules-img--large"}
              src={
                "https://storage.googleapis.com/writing-battle.appspot.com/judgeUploads/profilePicture/" +
                judgeData.userId +
                ".webp?alt=media&" +
                now.getTime().toString(10)
              }
            />
            {judgeData.bio ? (
              <div className="green-background--darkest selected-rules-judge-bio">
                <p className="description--large bold">
                  {judgeData.firstName +
                    (judgeData.lastName ? " " + judgeData.lastName : "")}
                </p>
                <p className="description--small white white-space">
                  {judgeData.bio}
                </p>
                <br />
                <br />
                {judgeData.bioLinks?.length > 0 ? (
                  <a
                    target="_blank"
                    href={judgeData.bioLinks[0]}
                    className="description--smaller text-dec--none white"
                  >
                    {judgeData.bioLinks[0].length < LINK_LIMIT
                      ? judgeData.bioLinks[0]
                      : judgeData.bioLinks[0].substring(0, LINK_LIMIT_MIN_TWO) +
                        "..."}
                  </a>
                ) : null}
                {judgeData.bioLinks?.length > 1 ? (
                  <>
                    <br />
                    <a
                      target="_blank"
                      href={judgeData.bioLinks[1]}
                      className="description--smaller text-dec--none white"
                    >
                      {judgeData.bioLinks[1].length < LINK_LIMIT
                        ? judgeData.bioLinks[1]
                        : judgeData.bioLinks[1].substring(
                            0,
                            LINK_LIMIT_MIN_TWO
                          ) + "..."}
                    </a>
                  </>
                ) : null}
                {judgeData.bioLinks?.length > 2 ? (
                  <>
                    <br />
                    <a
                      target="_blank"
                      href={judgeData.bioLinks[2]}
                      className="description--smaller text-dec--none white"
                    >
                      {judgeData.bioLinks[2].length < LINK_LIMIT
                        ? judgeData.bioLinks[2]
                        : judgeData.bioLinks[2].substring(
                            0,
                            LINK_LIMIT_MIN_TWO
                          ) + "..."}
                    </a>
                  </>
                ) : null}
              </div>
            ) : null}
          </div>
        );
      }
    } catch (err) {
      console.log(err);
      setSelectedJudgeUserId("");
      setRenderedSelectedJudge(null);
    }
  };

  useEffect(() => {
    if (selectedJudgeUserId) {
      renderSelectedJudge(selectedJudgeUserId);
    } else {
      setRenderedSelectedJudge(null);
    }
  }, [selectedJudgeUserId]);

  useEffect(() => {
    if (
      battleJudgesDoc?.battleId &&
      battleId &&
      battleJudgesDoc.battleId !== battleId
    ) {
      setRenderedJudgeList([]);
      setRenderedSelectedJudge(null);
      if (selectedJudgeUserId) {
        setSelectedJudgeUserId("");
      }
    } else if (battleJudgesDoc) {
      let arrayToUse = [];
      if (forIndustryJudges && battleJudgesDoc.industryJudges?.length) {
        arrayToUse = battleJudgesDoc.industryJudges;
      } else if (!forIndustryJudges && battleJudgesDoc.proJudges?.length) {
        arrayToUse = battleJudgesDoc.proJudges;
      }
      renderJudgeImages(arrayToUse, selectedJudgeUserId);
    }
  }, [battleJudgesDoc, battleId, selectedJudgeUserId]);

  const renderJudgeImages = async (arrayOfJudges, selectedUserId) => {
    const tempRender = [];
    const now = new Date();
    for (let i = 0; i < arrayOfJudges.length; i++) {
      const judge = arrayOfJudges[i];
      if (judge?.public && judge.userId) {
        tempRender.push(
          <img
            key={i.toString(10)}
            className={
              "judge-rules-img" +
              (selectedUserId && selectedUserId === judge.userId
                ? " judge-rules-img--selected"
                : "")
            }
            src={
              "https://storage.googleapis.com/writing-battle.appspot.com/judgeUploads/profilePicture/" +
              judge.userId +
              ".webp?alt=media&" +
              now.getTime().toString(10)
            }
            onClick={() => {
              setSelectedJudgeUserId(judge.userId);

              if (selectedJudgeRef?.current) {
                selectedJudgeRef.current.scrollIntoView(pageScrollSettings);
              }
            }}
          />
        );
      }
    }
    if (tempRender?.length) {
      setRenderedJudgeList(tempRender);
    }
  };

  if (!renderedJudgeList?.length) {
    return null;
  }

  return (
    <div className="padding-top-bot--smaller border-bottom-thin--green">
      <h2 className="heading-secondary center-text margin--none white">
        {forIndustryJudges ? "Industry Judges" : "Professional Judges"}
      </h2>
      <p className="description--small center-text white padding--small">
        {forIndustryJudges && renderedJudgeList?.length !== 5
          ? "All five Industry Judges will be announced before the Battle begins"
          : !forIndustryJudges
          ? "All Professional Judges will be announced before the Battle begins"
          : ""}
      </p>
      <br />
      {renderedSelectedJudge ? (
        <div
          ref={selectedJudgeRef}
          className="flex margin-auto justify-content--center"
        >
          {renderedSelectedJudge}
        </div>
      ) : null}
      <br />
      <div className="container">
        <div className="flex flex-wrap margin-auto justify-content--center">
          {renderedJudgeList}
        </div>
      </div>
    </div>
  );
}

export default JudgeList;
