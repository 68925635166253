function customWordCount(editor) {
  if (!editor) {
    return 0;
  }
  const text = editor.getText(); // Get all the text content
  // Replace line breaks with a space and remove special characters except spaces
  const filteredText = text
    .replace(/[\n\r]+/g, " ") // Replace newlines and carriage returns with a space
    .replace(/[^a-zA-Z0-9\s]+/g, ""); // Remove non-alphanumeric characters (except spaces)

  // Split the filtered text into words by spaces
  const words = filteredText.trim().split(/\s+/);

  // Return the number of words (non-empty strings)
  return words.filter(Boolean).length;
}

export function getCustomWordCount(editor) {
  return customWordCount(editor);
}
